import V from 'voUtils/V.js';
import DateEx from 'voUtils/DateEx.js';


import {
	doReq
}
from 'voUtils/RestEx.js';

import {
	findResultFields
}
from 'voUtils/BrowserEx.js';

export default {
	data: function () {
		return {
			units: []
		};
	},

	beforeMount: function () {
		var results = this.$el.getElementsByClassName('unit-list-section');
		if (results.length) {
			this.resultFields = findResultFields(results[0].innerHTML, this.$el);
		}
		var suffix = VOFFICE.token.substr(VOFFICE.token.length - 12);
		var savedUnits = JSON.parse(localStorage.getItem('lastVisitedUnits_' + suffix));
		var count = 0;
		var unitsIds = [];
		if (savedUnits) {
			count = Object.keys(savedUnits).length;
		}


		if (count > 0) {
			for (var index = 0; index < count; index++) {
				unitsIds.push(savedUnits[index]._id);
			}

			var req = {
				fields: this.resultFields,
				filter: {

				},
				ids: unitsIds
			};

			doReq('searchUnits', req)
				.then((res) => {
					this.units = res.units;

				});

		}

	},
	updated: function () {
		this.applySlider();
		$('[data-toggle="tooltip"]').tooltip();
		$('[data-toggle="popover"]').popover();
	},
	methods: {
		show: function () {
			if (this.units.length > 0) {
				return true;
			}
			return false;
		},
		getUnitSearchTarget: function (id) {
			if (window.screen.width < 768) {
				return '_self';
			} else {
				return 'unit_' + id;
			}
		},


		applySlider: function () {
			$(".owl-carousel.lastviewed-units").owlCarousel({
				loop: false,
				margin: 30,
				smartSpeed: 3000,
				autoplay: false,
				nav: true,
				responsive: {
					0: {
						items: 1
					},
					768: {
						items: 2
					},
					996: {
						items: 3
					},
					1200: {
						items: 4

					}
				}
			});
		}
	}

};